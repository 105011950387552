import React, {Suspense} from "react";
import {Route} from "../Routes/IRoute";
import {ServiceMachine} from "../Routes/Service/IService";
import Spinner from 'react-bootstrap/Spinner';

const Home = React.lazy(() => import(`../Routes/Home/Home`));
const About = React.lazy(() => import(`../Routes/About/About`));
const Service = React.lazy(() => import(`../Routes/Service/Service`));
const Gallery = React.lazy(() => import(`../Routes/Gallery/Gallery`));
const Contact = React.lazy(() => import(`../Routes/Contact/Contact`));
const Legal = React.lazy(() => import(`../Routes/Legal/Legal`));

export const uri: string = ``;
export const routes: Route[] = [
    {
        path: `${uri}/home`,
        name: `Home`,
        label: `Home`,
        match: [``, `home`],
        element: <Suspense fallback={<div className={"loadingSpinner"}><Spinner animation="grow" /></div>}><Home/></Suspense>,
        show: true
    },
    {
        path: `${uri}/`,
        name: `Home`,
        label: `Home`,
        match: [``, `home`],
        element: <Suspense fallback={<div className={"loadingSpinner"}><Spinner animation="grow" /></div>}><Home/></Suspense>,
        show: false
    },
    {
        path: `${uri}/about`,
        name: `Über uns`,
        label: `About`,
        match: [`about`],
        element: <Suspense fallback={<div className={"loadingSpinner"}><Spinner animation="grow" /></div>}><About/></Suspense>,
        show: true
    },
    {
        path: `${uri}/service`,
        name: `Service`,
        label: `Service`,
        match: [`service`],
        element: <Suspense fallback={<div className={"loadingSpinner"}><Spinner animation="grow" /></div>}><Service/></Suspense>,
        show: true
    },
    {
        path: `${uri}/gallery`,
        name: `Galerie`,
        label: `Gallery`,
        match: [`gallery`],
        element: <Suspense fallback={<div className={"loadingSpinner"}><Spinner animation="grow" /></div>}><Gallery/></Suspense>,
        show: true
    },
    {
        path: `${uri}/contact`,
        name: `Kontaktformular`,
        label: `Contact`,
        match: [`contact`],
        element: <Suspense fallback={<div className={"loadingSpinner"}><Spinner animation="grow" /></div>}><Contact/></Suspense>,
        show: true
    },
    {
        path: `${uri}/legal`,
        name: `Legal`,
        label: `Legal`,
        match: [`legal`],
        element: <Suspense fallback={<div className={"loadingSpinner"}><Spinner animation="grow" /></div>}><Legal/></Suspense>,
        show: false

    }
]
export const defaultRoute: Route = routes[0];
export const images: { [name: string]: string[] } = {
    "Fertigung": [
        `.${uri}/images/Fertigung/f1.webp`,
        `.${uri}/images/Fertigung/f2.webp`,
        `.${uri}/images/Fertigung/f3.webp`,
        `.${uri}/images/Fertigung/f4.webp`,
        `.${uri}/images/Fertigung/f5.webp`,
        `.${uri}/images/Fertigung/f6.webp`,
        `.${uri}/images/Fertigung/f7.webp`,
        `.${uri}/images/Fertigung/f8.webp`,
        `.${uri}/images/Fertigung/f9.webp`,
        `.${uri}/images/Fertigung/f10.webp`,
        `.${uri}/images/Fertigung/f11.webp`,
        `.${uri}/images/Fertigung/f12.webp`,
        `.${uri}/images/Fertigung/f13.webp`,
        `.${uri}/images/Fertigung/f14.webp`,
        `.${uri}/images/Fertigung/f15.webp`,
        `.${uri}/images/Fertigung/f16.webp`,
        `.${uri}/images/Fertigung/f17.webp`,
        `.${uri}/images/Fertigung/f18.webp`,
        `.${uri}/images/Fertigung/f19.webp`,
        `.${uri}/images/Fertigung/f20.webp`,
        `.${uri}/images/Fertigung/f21.webp`,
        `.${uri}/images/Fertigung/f22.webp`,
        `.${uri}/images/Fertigung/f23.webp`,
        `.${uri}/images/Fertigung/f24.webp`,
        `.${uri}/images/Fertigung/f25.webp`,
        `.${uri}/images/Fertigung/f26.webp`,
        `.${uri}/images/Fertigung/f27.webp`,
        `.${uri}/images/Fertigung/f28.webp`,
        `.${uri}/images/Fertigung/f29.webp`,
        `.${uri}/images/Fertigung/f30.webp`,
        `.${uri}/images/Fertigung/f31.webp`,
        `.${uri}/images/Fertigung/f32.webp`,
        `.${uri}/images/Fertigung/f33.webp`,
        `.${uri}/images/Fertigung/f34.webp`,
        `.${uri}/images/Fertigung/f35.webp`,
        `.${uri}/images/Fertigung/f36.webp`,
        `.${uri}/images/Fertigung/f37.webp`,
        `.${uri}/images/Fertigung/f38.webp`,
        `.${uri}/images/Fertigung/f39.webp`,
        `.${uri}/images/Fertigung/f40.webp`,
        `.${uri}/images/Fertigung/f41.webp`,
        `.${uri}/images/Fertigung/f42.webp`,
        `.${uri}/images/Fertigung/f43.webp`,
        `.${uri}/images/Fertigung/f44.webp`,
        `.${uri}/images/Fertigung/f45.webp`,
        `.${uri}/images/Fertigung/f46.webp`,
        `.${uri}/images/Fertigung/f47.webp`,
        `.${uri}/images/Fertigung/f48.webp`,
        `.${uri}/images/Fertigung/f49.webp`,
        `.${uri}/images/Fertigung/f50.webp`,
        `.${uri}/images/Fertigung/f51.webp`,
        `.${uri}/images/Fertigung/f52.webp`,
        `.${uri}/images/Fertigung/f53.webp`,
        `.${uri}/images/Fertigung/f54.webp`,
        `.${uri}/images/Fertigung/f55.webp`,
        `.${uri}/images/Fertigung/f57.webp`,
        `.${uri}/images/Fertigung/f58.webp`,
        `.${uri}/images/Fertigung/f59.webp`,
    ],
    "Reparatur und Wartung": [
        `.${uri}/images/ReparaturUndWartung/rw1.webp`,
        `.${uri}/images/ReparaturUndWartung/rw2.webp`,
        `.${uri}/images/ReparaturUndWartung/rw3.webp`,
        `.${uri}/images/ReparaturUndWartung/rw4.webp`,
        `.${uri}/images/ReparaturUndWartung/rw5.webp`,
        `.${uri}/images/ReparaturUndWartung/rw6.webp`,
        `.${uri}/images/ReparaturUndWartung/rw7.webp`,
        `.${uri}/images/ReparaturUndWartung/rw8.webp`,
        `.${uri}/images/ReparaturUndWartung/rw9.webp`,
    ],
    "Maschinentransport": [
        `.${uri}/images/Maschinentransport/m1.webp`,
        `.${uri}/images/Maschinentransport/m2.webp`,
        `.${uri}/images/Maschinentransport/m3.webp`,
        `.${uri}/images/Maschinentransport/m4.webp`,
        `.${uri}/images/Maschinentransport/m5.webp`,
        `.${uri}/images/Maschinentransport/m6.webp`,
        `.${uri}/images/Maschinentransport/m7.webp`,
        `.${uri}/images/Maschinentransport/m8.webp`,
    ],
    "Stahlbau": [
        `.${uri}/images/Stahlbau/s1.webp`,
        `.${uri}/images/Stahlbau/s2.webp`,
        `.${uri}/images/Stahlbau/s3.webp`,
        `.${uri}/images/Stahlbau/s4.webp`,
        `.${uri}/images/Stahlbau/s5.webp`,
        `.${uri}/images/Stahlbau/s6.webp`,
        `.${uri}/images/Stahlbau/s7.webp`,
        `.${uri}/images/Stahlbau/s8.webp`,
        `.${uri}/images/Stahlbau/s9.webp`,
        `.${uri}/images/Stahlbau/s10.webp`,
        `.${uri}/images/Stahlbau/s11.webp`,

    ],
}

export const machines: ServiceMachine[] = [
    {
        marke: `Hedelius`,
        typ: `BC 50`,
        maschinenTyp: `CNC Fräsmaschine`,
        fertingungsverfahren: `fräsen`,
        bearbeitung: [[`X`, `2100mm`, true], [`Y`, `510mm`, true], [`Z`, `510mm`, false], [` + 200mm `, `Aufbau`, false]],
        img: `.${uri}/images/Site/Machines/HedeliusBC50.webp`,
    },
    {
        marke: `Mazak`,
        typ: `QT 30`,
        maschinenTyp: `CNC Drehmaschine`,
        fertingungsverfahren: `drehen`,
        bearbeitung: [[`ø`, `300mm`, true], [`L`, `1000mm`, true]],
        img: `.${uri}/images/Site/Machines/MazakQT30.webp`,

    },
    {
        marke: `Colchester Mascot`,
        typ: `1600`,
        maschinenTyp: `Drehmaschine`,
        fertingungsverfahren: `drehen`,
        bearbeitung: [[`ø`, `300mm`, true], [`L`, `1000mm`, false]],
        img: null,

    },
    {
        marke: `Deckel`,
        typ: `FP 2`,
        maschinenTyp: `Fräsmaschine`,
        fertingungsverfahren: `fräsen`,
        bearbeitung: [[`X`, `400mm`, true], [`Y`, `300mm`, true], [`Z`, `200mm`, false]],
        img: null,
    },
    {
        marke: `Jung`,
        typ: `HF 50`,
        maschinenTyp: `Flachschleifmaschine`,
        fertingungsverfahren: `flachschleifen`,
        bearbeitung: [[`L`, `500mm`, true], [`B`, `200mm`, true], [`H`, `200mm`, false]],
        img: null,
    },
    {
        marke: `Fortuna`,
        typ: ``,
        maschinenTyp: `Rundschleifmaschine`,
        fertingungsverfahren: `rundschleifen`,
        bearbeitung: [[`ø`, `200mm`, true], [`L`, `400mm`, false]],
        img: null,
    },
    {
        marke: `Behringer`,
        typ: `HBP 303A`,
        maschinenTyp: `Bandsäge`,
        fertingungsverfahren: `bandsägen`,
        bearbeitung: [[`L`, `300mm`], [`B`, `300mm`]],
        img: null,
    }
]
export const performances: string[] = [
    `Inbetriebnahme und Reparatur von Werkzeugmaschinen`,
    `Reparatur von Mazak-Fräsmaschinen`,
    `Geometrieüberprüfung`,
    `Maschinentransporte bis 16t bei Ihnen vor Ort`,
    `Maschinenreinigung und Technische Reinigung mit Heißdampfreiniger`,
    `Stahlbau- und Industriemontage`,
    `CNC Dreh- und Frästeile`,
    `Flach- und Rundschleifen`,
    `Vor- und Baugruppen Montage`,
    `CAD-Konstruktionen aller Art`
];