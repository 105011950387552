import React from "react";
import Logo from "./Logo";
import Banner from "./Banner";

import Container from "react-bootstrap/Container";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {IHeader, NavLinkState} from "./IHeader";
import {withRouter} from "../Utility/withRouter";
import {routes, uri} from "../Utility/Static";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class Header extends React.Component<any, {
    activity: NavLinkState[],
    show: boolean,
    windowWidth: number
}> implements IHeader {

    constructor(props: any) {
        super(props);
        const path = window.location.pathname.replace(uri, "").split("/")[1]?.toLowerCase() || "";

        this.state = {
            activity: [],
            show: false,
            windowWidth: window.innerWidth
        }
        routes.filter((route) => route.show).forEach((route) => {
            this.state.activity.push({
                href: route.path,
                text: route.name,
                class: route.match.includes(path) ? "active" : "inactive"
            })
        })
        window.onresize = () => {
            this.setState({windowWidth: window.innerWidth})
        }
        this.setTitle();
    }

    setTitle() {
        let arr = window.location.pathname.split("/");
        let t = arr[arr.length - 1];
        if (t === "") t = "Home";
        t = t.charAt(0).toUpperCase() + t.slice(1);
        document.title = t;
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
        this.setTitle()
    }

    render() {
        if (this.state.windowWidth <= 800) {
            return (
                <div className={"header"}>
                    <Banner/>
                    <Container className={"fullWidth fullHeight flexColumn alignContentCenter"}>
                        <Navbar.Brand href={uri}><Logo/></Navbar.Brand>
                        <Button onClick={() => this.setState({show: true})} className={"menubutton"} title={"ShowMenu"}><FontAwesomeIcon
                            icon={"bars"}/></Button>
                        <Offcanvas show={this.state.show}
                                   onHide={() => this.setState({show: false})}
                                   className={"offcanvas"}
                                   responsive={"lg"}
                                   placement={"top"}
                                   backdropClassName={"flexColumn alignCenter"}
                        >
                            <Offcanvas.Header closeButton>
                                <Banner style={{height: "100%"}}/>
                                <Logo/>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav fill className={"alignContentCenter"}>
                                    {
                                        this.state.activity.map((route: NavLinkState) => <Nav.Link
                                            className={route.class}
                                            key={route.href}
                                            href={route.href}
                                        >{route.text}
                                        </Nav.Link>)
                                    }
                                </Nav>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </Container>
                </div>
            )
        }


        return (
            <div className={"header"}>
                <Navbar variant="light" bg="light" sticky="top">
                    <Banner/>
                    <Container className={"fullWidth fullHeight flexColumn align-center"}>
                        <Navbar.Brand href={uri} className={"marginAuto"}><Logo/></Navbar.Brand>
                        <Nav fill>
                            {
                                this.state.activity.map((route: NavLinkState) => <Nav.Link
                                    className={route.class}
                                    key={route.href}
                                    href={route.href}
                                >{route.text}
                                </Nav.Link>)
                            }
                        </Nav>
                    </Container>
                </Navbar>
            </div>
        );
    }
}

export default withRouter(Header);