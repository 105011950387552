import React, {Suspense} from 'react';

import './App.css';
import CookieConsent from "./Routes/Legal/CookieConsent";
import NotFound from "./Routes/Error/NotFound";
import {library} from '@fortawesome/fontawesome-svg-core'

import {faFacebookF} from "@fortawesome/free-brands-svg-icons/faFacebookF";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";
import {faBars} from '@fortawesome/free-solid-svg-icons/faBars'
import {faCircleCheck} from "@fortawesome/free-solid-svg-icons/faCircleCheck";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faPhone} from "@fortawesome/free-solid-svg-icons/faPhone";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";

import {createBrowserRouter, createRoutesFromChildren, Navigate, Route, RouterProvider} from "react-router-dom";

import Spinner from 'react-bootstrap/Spinner';

import {routes} from "./Utility/Static";
import {withCookies} from "react-cookie";

library.add(faInstagram, faFacebookF, faBars, faCircleCheck, faEnvelope, faPhone, faMapMarkerAlt);

const router = createBrowserRouter(
    createRoutesFromChildren(
        <Route path={"/"} errorElement={<NotFound/>}>
            {
                routes.map((route) => {
                    return <Route path={route.path} element={route.element} key={route.path}/>
                })
            }
            <Route path="*" element={<NotFound status={404}/>}/>
        </Route>
    )
);

class App extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            firstvisited: !props.cookies.get("cookieConsent")
        }

    }


    render() {
        return (
            <Suspense fallback={<div className={"loadingSpinner"}><Spinner animation="grow" /></div> }>
                <CookieConsent show={this.state.firstvisited}/>
                <RouterProvider router={router}/>
            </Suspense>
        )
    }
}

export default withCookies(App);
