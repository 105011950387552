import React from "react";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import {defaultRoute, routes} from "../../Utility/Static";
import {withRouter} from "../../Utility/withRouter";

export class NotFound extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        document.title = "404";
    }
    render() {
        return (
            <div className={"page"}>
                <Header/>
                <div className={"content flexColumn alignContentCenter marginAuto"}>
                    <div className={"notFound fullWidth alignContentCenter flexColumn"}>
                        <span className={"fourOhFour"}>404</span>
                        <h2>Diese Seite existiert nicht, oder wurde gelöscht</h2>
                        <button className={"contactButton fourOhFourButton"} onClick={
                            () => {
                                this.props.navigate((routes.find(route => route.label === "Home"))?.path || defaultRoute.path)
                            }
                        }>Home</button>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default withRouter(NotFound);