export const FACEBOOK_LINK = "https://www.facebook.com/people/JB-Maschinenbau/100054327950213/"
export const INSTAGRAM_LINK = "https://www.instagram.com/jb.maschinenbau/"
export const PHONE_NUMBER = "+49 170 48 29 344"
export const BUSINESS_NAME_SHORT = "JB Maschinenbau & Montageservice"
export const BUSINESS_NAME_LONG = "Jörg Bühler Maschinenbau & Montageservice"
export const OWNER = "Jörg Bühler"
export const STREET = "Breitestraße 5"
export const CITY = "79331 Teningen - Köndringen"
export const COUNTRY = "Deutschland"
export const EMAIL = "Info@JB-Maschinenbau.de"
export const COPYRIGHT = "© 2022 JB Maschinenbau & Montageservice"
