import React from "react";
import {uri} from "../Utility/Static";

export default class Banner extends React.Component<any, any> {
    render() {
        return (
            <div className={"bgBannerWrapper"} style={{...this.props.style}}>
                <img src={`.${uri}/images/Site/banner.webp`} alt={"Banner"}
                     className={"bgBanner fullHeight fullWidth"}
                     width={"100%"}
                        height={"100%"}
                />
            </div>
        );
    }
}