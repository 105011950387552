import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {CITY, COPYRIGHT, EMAIL, FACEBOOK_LINK, INSTAGRAM_LINK, PHONE_NUMBER, STREET} from "../Utility/Content";
import {Link} from "react-router-dom";
import {withRouter} from "../Utility/withRouter";
import {defaultRoute, routes} from "../Utility/Static";

class Footer extends React.Component<any, any> {
    render() {
        return <div className={"footer fullWidth"}>
            <div className={"footerTop flexRow alignContentCenter alignTextCenter fullWidth"}>Nehmen Sie direkt mit uns
                Kontakt auf und erkundigen Sie sich...
            </div>
            <div className={"footerCenter flexRow fullWidth"}>
                <div className={"footerColumn"}>
                    <div className={"footerColumnTitle flexRow alignContentCenter"}>Social Media</div>
                    <div className={"footerColumnContent socials flexRow marginAuto"}>
                        <a href={INSTAGRAM_LINK} title={"Instagram Link"}><FontAwesomeIcon icon={["fab", "instagram"]}
                                                                                           color={"white"}
                                                                                           size={"3x"}/></a>
                        <a href={FACEBOOK_LINK} title={"Facebook Link"}><FontAwesomeIcon icon={["fab", "facebook-f"]}
                                                                                         color={"white"}
                                                                                         size={"3x"}/></a>
                    </div>
                </div>
                <div className={"footerColumn"}>
                    <div className={"footerColumnTitle flexRow alignContentCenter"}>Anschrift</div>
                    <div className={"footerColumnContent address fullWidth"}>
                        <div className={"addressRow fullWidth flexRow"}><FontAwesomeIcon
                            icon={["fas", "location-dot"]}/><a
                            title={"Adresse auf Google Maps"}
                            rel={"noreferrer"} href="https://goo.gl/maps/S4RgKuKUSfN1EiUB6"
                            target={"_blank"}>{STREET}<br/>{CITY}</a></div>
                        <div className={"addressRow fullWidth flexRow"}><FontAwesomeIcon icon={["fas", "phone"]}/><a
                            title={"Telefonnummer anrufen"}
                            rel={"noreferrer"}
                            href={"tel:" + PHONE_NUMBER}>{PHONE_NUMBER}</a>
                        </div>
                        <div className={"addressRow fullWidth flexRow"}><FontAwesomeIcon icon={["fas", "envelope"]}/><a
                            title={"E-Mail schreiben"}
                            rel={"noreferrer"}
                            href={"mailto:" + EMAIL}
                            target={"_blank"}>{EMAIL}</a>
                        </div>
                    </div>
                </div>
                <div className={"footerColumn"}>
                    <div className={"footerColumnTitle flexRow alignContentCenter"}>Kontaktformular</div>
                    <div className={"footerColumnContent alignTextCenter"}>
                        <Link to={routes.find(route => route.label === "Contact")?.path || defaultRoute.path}
                              className={"contact alignTextCenter"}>Hier können Sie uns Kontaktieren</Link>
                    </div>
                </div>
            </div>
            <div className={"footerBottom flexColumn fullWidth"}>
                <Link className={"legal alignTextCenter"} to={routes.find(route => route.label === "Legal")?.path || defaultRoute.path}>Datenschutz
                    / Impressum / AGBs</Link>
                <p className={"copyright"}>{COPYRIGHT}</p>
            </div>
        </div>
    }
}

export default withRouter(Footer);