import React from "react";
import {Form} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {withCookies} from "react-cookie";

class CookieConsent extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            show: this.props.show,
            analytical: false,
            functional: false
        }
    }

    submit() {
        this.props.cookies.set("ga-disable-G-YFR60SD56T", !this.state.analytical);
        //@ts-ignore
        window["ga-disable-G-YFR60SD56T"] = !this.state.analytical;

        this.props.cookies.set("cookieConsent", (this.state.functional | (this.state.analytical << 1)))
        this.setState({show: false})
    }

    change(type: string, value: boolean) {
        switch (type) {
            case "analytics":
                this.setState({analytical: value})
                break;
            case "functional":
                this.setState({functional: value})
        }
    }

    render() {
        return (
            this.state.show ?
                <div className={"cookies flexRow"}>
                    <p>
                        Diese Website verwendet Cookies und andere tracking technologien, um Ihnen eine bessere
                        online Erfahrung zu bieten, den Verkehr auf dieser Website zu analysieren und zu verstehen wo
                        unsere Nutzer herkommen.

                        Mehr infos hierzu im <a href={"/legal#preferences"} style={{textDecoration: "underline"}}>Preference
                        center</a>

                    </p>
                    <br/>
                    <Form className={"flexRow"}>
                        <div className={"SwitchField"}>
                            <Form.Check
                                type="switch"
                                label={"Strictly Necessary"}
                                disabled={
                                    true
                                }
                                checked={true}
                            />
                            <Form.Check
                                type={"switch"}
                                label={"Functional Cookies"}
                                onChange={(event) => this.change("functional", event.target.checked)}
                            />
                            <Form.Switch
                                type={"switch"}
                                label={"Analytical"}
                                onChange={(event) => this.change("analytics", event.target.checked)}
                            />
                        </div>

                        <Button className={"SubmitButton"} onClick={() => {
                            this.submit()
                        }}>
                            Submit
                        </Button>
                    </Form>
                </div>
                : undefined
        );
    }
}

export default withCookies(CookieConsent);