import React from "react";
import '../App.css'
import {uri} from "../Utility/Static";

export default class Logo extends React.Component<any, any> {
    render() {
        return (
            <img src={`.${uri}/images/Site/logo.webp`}
                 className={"logo"} alt={"Logo"}/>
        );
    }
}